import { fromEvent, Observable } from "rxjs";
import { filter, map } from 'rxjs/operators';
import { ADMIN_LANDINS } from 'src/environments/environment';
import { LandingConfiguration } from '../model/landing-configuration.model';

export class IFrameUtils {
  private static load(): void {
    window.parent.postMessage({
      type: 'load_home_ssr'
    }, '*');;
  }
  public static configureLanding(landing: LandingConfiguration): Observable<LandingConfiguration | undefined> {
    IFrameUtils.load();
    return fromEvent(window || {}, 'message')
      .pipe(
        filter((message: any) => ADMIN_LANDINS.split(',').some(origin => message.origin.includes(origin))),
        map((message: MessageEvent) => message.data),
        filter(message => message.type === 'message' || message.type === 'hightLight'),
        map((resp: any) => {
          let landingResp: LandingConfiguration | undefined = { ...landing };
          if (resp.type === 'message' && resp.configLanding) {
            landingResp.elements = resp.configLanding.elements;
            landingResp.meta = resp.configLanding.meta;
          } else if (resp.type === 'hightLight' && resp.configLanding) {
            landingResp.targetRowId = resp.configLanding.targetRowId;
          } else {
            landingResp = undefined;
          }
          return landingResp;
        }))
  }
}