import { Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { ApisService } from './apis.service';
import { Router } from '@angular/router';
import { LandingConfiguration } from '../../core/model/landing-configuration.model';
import { DEFAULT_LANDING_CONFIGURATION } from '../../core/constant/default-landing-configuration.constant';
import { LandingImages } from '../../core/model/landing-images.model';
import { LandingMeta } from '../../core/model/landing-meta.model';
import { LANDINGS_URL } from '../../../environments/environment';
import { Source } from '../../core/enums/source.enum';
import { MetaService } from './meta.service';
import { getAllianceSourceFromUrl } from 'src/app/core/utils/get-alliance-source.util';
import { Alliances } from 'src/app/core/model/alliances.model';
import { GoogleAnalyticsService } from './google-analytics.service';
import { IFrameUtils } from 'src/app/core/utils/iframe.utils';
import { AlliancesService } from './alliances.service';

@Injectable({
  providedIn: 'root'
})
export class LandingSetupService {

  private _cachedAlliances?: Observable<Alliances>;
  private _landingConfiguration = signal<LandingConfiguration>(DEFAULT_LANDING_CONFIGURATION)
  private _source$ = new Subject<Source | undefined>();

  private elementToNavigate!: Element | null;

  constructor(
    private apiService: ApisService,
    private router: Router,
    private alliancesService: AlliancesService,
    private metaService: MetaService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) { }

  get landingConfiguration() {
    return this._landingConfiguration.asReadonly();
  }

  setLandingConfiguration(value: LandingConfiguration) {
    this._landingConfiguration.set(value);
  }

  requestLandingConfiguration({ slug, data = 'landings', url, callback }:
    { slug?: string, data?: keyof typeof LANDINGS_URL, url?: string, callback?: () => void }): void {
    const _url = (url ?? this.router.url).replace('index.html', '');
    const _slug = slug ?? (_url?.substring(1)?.split('?')[0] || 'home');
    this.getSourceActive(_url).subscribe((sourceResp) => {
      this.apiService.getLandingConfiguration(data, _slug)
        .subscribe({
          next: (resp) => {
            callback && callback();
            this.buildLandingConfiguration(sourceResp, resp);
            if (resp.meta) {
              this.metaService.execServer.buildMetaLanding(resp.meta)
            }
            this.metaService.execServer.addFAQStructuredData(resp.elements);
          },
          error: (resp) => {
            console.error(JSON.stringify(resp));
          }
        });
    })
  }

  getAlliances(): Observable<Alliances> {
    if (!this._cachedAlliances) {
      this._cachedAlliances = this.apiService.alliances();
    }
    return this._cachedAlliances;
  }

  getSourceActive(url: string) {
    return this.getAlliances().pipe(
      map(alliances => alliances.filter(alliance => alliance.active)),
      map(alliances => alliances.map(alliance => alliance.name)),
      map(alliances => getAllianceSourceFromUrl(alliances, url)));
  }

  buildLandingConfiguration(source: Source | undefined, resp: LandingConfiguration): void {
    source = source ?? resp.source ?? Source.COCHA;
    this._source$.next(source);

    const linkLogo = source !== Source.COCHA
      ? `${DEFAULT_LANDING_CONFIGURATION.linkLogo}${source}`
      : `${DEFAULT_LANDING_CONFIGURATION.linkLogo}`;

    const configSource = this.getConfigFromSource(source, resp.meta!);

    this._landingConfiguration.update(value => ({
      ...value,
      vertical: this.getVerticalFromRouting((resp as any).path),
      source: source,
      id: resp.id,
      elements: resp.elements,
      meta: resp.meta,
      images: configSource.images,
      phone: configSource.phone,
      campaign: this.alliancesService.getCampaign(),
      linkLogo: linkLogo,
    }));
    this.googleAnalyticsService.pushImpresions(this._landingConfiguration());
  }

  getVerticalFromRouting(route: string = '') {
    if (/hotel/gi.test(route.toLocaleLowerCase())) {
      return 'hotel';
    } else if (/pkg|paquete/gi.test(route.toLocaleLowerCase())) {
      return 'package';
    } else if (/vuelo|flight/gi.test(route.toLocaleLowerCase())) {
      return 'flight';
    } else if (/asistencia|al|viajero/gi.test(route.toLocaleLowerCase())) {
      return 'tripAssistance';
    } else {
      return DEFAULT_LANDING_CONFIGURATION.vertical;
    }
  }

  getConfigFromSource(source: Source, meta: LandingMeta): { images: LandingImages, phone: string } {
    const images: LandingImages = {
      backgroundImageDesktop: meta?.images?.background || LANDINGS_URL.landing_background,
      backgroundImageMobile: meta?.images?.bgmobile || LANDINGS_URL.landing_background,
      //Logos
      logoDesktop: `${LANDINGS_URL.landing_alliances_images}/${source}-pbc-header.png`,
      logoMobile: `${LANDINGS_URL.landing_alliances_images}/${source}-pbc-header-m.png`,
    };
    let banner;
    let phone = '(+562) 2 464 1300';

    switch (source) {
      case Source.ITAU:
        banner = {
          bannerBackgroundColor: '#F47920',
          bannerImage: `${LANDINGS_URL.landing_alliances_images}/cuotas/10-sin-interes.png`,
        };
        phone = '(+562) 2 464 3300';
        break;
      case Source.RIPLEY:
        banner = {
          bannerBackgroundColor: '#523178',
          bannerImage: `${LANDINGS_URL.landing_alliances_images}/cuotas/ripley-cuotas.png`,
        };
        phone = '(+562) 2 464 2360';
        break;
      case Source.CENCOSUD:
        banner = {
          bannerBackgroundColor: '#EF7C09',
          bannerImage: `${LANDINGS_URL.landing_alliances_images}/cuotas/cuotas-cencosud.png`,
        };
        phone = '(+562) 2 394 3750';
        break;
      case Source.TENPO:
        banner = {
          bannerBackgroundColor: '#EF7C09',
          bannerImage: `${LANDINGS_URL.landing_alliances_images}/cuotas/cuotas-tenpo.png`,
        };
        break;
      case Source.BICE:
        phone = '(+562) 2 464 2350';
        break;
      case Source.BENEFICIOS:
        phone = '(+562) 2 464 1364';
        break;
    }
    return {
      images: { ...images, ...banner },
      phone
    };
  }

  getSource$() {
    return this._source$.asObservable();
  }

  listenIFramePostMessagesLanding() {
    const _url = this.router.url;
    this.getSourceActive(_url).subscribe((source) => {
      this.buildLandingConfiguration(source, this.landingConfiguration());
      IFrameUtils.configureLanding(Object.assign({}, this.landingConfiguration()))
        .subscribe({
          next: (response?: LandingConfiguration) => {
            if (response) {
              this.buildLandingConfiguration(source, response);
              if (response.targetRowId) {
                this.navigateToElementHighlight(response.targetRowId);
              }
            }
          }
        });
    });
  }

  navigateToElementHighlight(targetRowId: string) {
    setTimeout(() => {
      if (this.elementToNavigate) {
        this.elementToNavigate.classList.remove('HIGHTLIGHT_SCROLL_ROW');
        this.elementToNavigate = null;
      }
      if (targetRowId) {
        this.elementToNavigate = document.querySelector('[targetrowid=' + targetRowId + ']');
      }
      if (this.elementToNavigate) {
        const y = this.elementToNavigate.getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
        this.elementToNavigate.classList.add('HIGHTLIGHT_SCROLL_ROW');
      }
    });
  };
}
