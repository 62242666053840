import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, makeStateKey, PLATFORM_ID } from '@angular/core';
import { ALLIANCES, COUPONS_DISCOUNT, LANDINGS_URL, MY_ACCOUNT_CONFIG } from 'src/environments/environment';
import { map, Observable, shareReplay } from 'rxjs';
import { Alliances } from '../../core/model/alliances.model';
import { isPlatformBrowser } from '@angular/common';
import { mapImageSourceElements } from '../../core/utils/map-image-source-elements.util';
import { LandingConfiguration } from '../../core/model/landing-configuration.model';
import { DiscountCoupon } from 'src/app/core/model/discount-coupon.model';

export const NOT_FOUND = 'No encontrado';
const LANDINGS_CONFIGURATION_KEY = makeStateKey<any>('landings-configuration');

@Injectable({
  providedIn: 'root'
})
export class ApisService {
  constructor(
    private httpService: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object,
  ) { }

  subscribeToNewsletter(url: string, data: any) {
    return this.httpService.post(url, data);
  }

  alliances(): Observable<Alliances> {
    return this.httpService.get<Alliances>(ALLIANCES, {}).pipe(
      map((alliances) => alliances.filter(alliance => alliance.active)),
      shareReplay(1),
    );
  }

  getLandingConfiguration(service: keyof typeof LANDINGS_URL, slug: string) {
    let url = `${LANDINGS_URL[service]}/${slug}`;
      return this.httpService.get<LandingConfiguration>(url).pipe(
        map((resp) => {
          if (!resp) {
            throw new HttpErrorResponse({ error: NOT_FOUND, status: 404 });
          }
  
          if (resp?.meta?.labels?.redirect && isPlatformBrowser(this.platformId)) {
            window.location.href = resp?.meta?.labels?.redirect;
          }
          resp.elements = mapImageSourceElements(resp.elements);
          return resp;
        }),
      )
  }


  getCouponsDiscount(): Observable<DiscountCoupon> {
    return this.httpService.get<DiscountCoupon>(COUPONS_DISCOUNT, {});
  }

  getMyAccountConfig(): Observable<{ active: boolean }> {
    return this.httpService.get<{ active: boolean }>(MY_ACCOUNT_CONFIG, {});
  }
}