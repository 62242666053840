import { Component, computed, effect, ElementRef, input, Renderer2, viewChild } from '@angular/core';
import { IsMobileService, CarouselComponent, CarouselItemDirective } from '@cocha/ngx-codex';
import { NgClass, NgStyle } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { GOOGLE_ANALYTICS_EVENTS } from 'src/app/core/constant/google-analytics-events.contant';
@Component({
  selector: 'landing-tips',
  templateUrl: './landing-tips.component.html',
  styleUrls: ['./landing-tips.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    CarouselComponent,
    CarouselItemDirective,
  ]
})
export class LandingTipsComponent {
  columns = input.required<number | string>();
  title = input.required<string>();
  bgcolor = input<string>();
  data = input<any[]>([]);

  dataByColumns = computed(() => {
    const chunkSize = Number(this.columns());
    const dataCopy = [...this.data()];
    const columnsData: any[][] = [];

    while (dataCopy.length) {
      const chunk = dataCopy.splice(0, chunkSize);
      columnsData.push(chunk);
    }
    return columnsData
  });

  isMobile = toSignal(this.isMobileService.checkMobile$(), { initialValue: true });
  containerElem = viewChild.required<ElementRef<HTMLElement>>('containerElem');

  constructor(
    private isMobileService: IsMobileService,
    private renderer: Renderer2,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    effect(() => {
      const bgColor = this.bgcolor();
      if (bgColor) {
        this.renderer.setProperty(this.containerElem().nativeElement, '--bg-tips-color', bgColor);
      }
    })
  }

  goTo(deal: any) {
    this.googleAnalyticsService.sendGoogleAnalitycsEvent({
      category: GOOGLE_ANALYTICS_EVENTS.category.tip,
      action: GOOGLE_ANALYTICS_EVENTS.action.click,
      label: deal.title,
      nonInteraction: false,
    });
    const url = deal.deeplink;
    url && window.open(url, '_blank');
  }
}
